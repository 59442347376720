@charset "utf-8";

/* default setting --------------------------------------------------------*/
/* font */
@font-face {
  font-display: swap;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: local("NotoSansCJKkr-Regular"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.woff)
      format("woff"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Regular.otf)
      format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: local("NotoSansCJKkr-Medium"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.woff)
      format("woff"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Medium.otf)
      format("opentype");
}

@font-face {
  font-display: swap;
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: local("NotoSansCJKkr-Bold"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff2)
      format("woff2"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.woff)
      format("woff"),
    url(https://fonts.gstatic.com/ea/notosanskr/v2/NotoSansKR-Bold.otf)
      format("opentype");
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select {
  vertical-align: middle;
}

* {
  font-family: "Noto Sans KR" !important;
  box-sizing: border-box;
}

:root {
  --gray100: #292a2b;
  --gray200: #363a3c;
  --gray300: #4d5256;
  --gray400: #777c80;
  --gray450: #86878c;
  --gray500: #a9afb3;
  --gray600: #ced3d6;
  --green100: #00c25f;
  --greendark: #1c9241;
  --green200: #00dc6c;
  --white: #ffffff;
}

body {
  overflow: hidden;
  color: var(--gray100);
}
p {
  letter-spacing: -0.004em;
  line-height: 150%;
  font-style: normal;
}
a {
  text-decoration: none;
}
.hide {
  opacity: 0;
}
.btn {
  line-height: 160%;
  border-radius: 4px;
  font-weight: 500;
  display: inline-block;
}
.btn-wide {
  width: 100%;
}
.btn:active {
  filter: brightness(92%);
  transform: scale(0.99);
  transition: all 200ms;
}
.btn:hover {
  cursor: pointer;
}
.onboarding-section {
  transition: opacity 0.35s;
}
/* header */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 13.333vw;
  width: 100%;
}
.btn-icon {
  display: flex;
  padding: 3.333vw;
  margin-left: 1.111vw;
  transition: all 200ms;
  background: #fff;
  border-radius: 100% !important;
}
.btn > img {
  width: 6.667vw;
  height: 6.667vw;
}
.btn-header {
  padding: 1.667vw 2.222vw;
  margin-right: 2.222vw;
  font-size: 3.889vw;
  background: #fff;
  border-radius: 1.111vw;
  color: var(--gray500);
  transition: all 200ms;
}
/* section */
.swipe-section {
  overflow: hidden;
  visibility: hidden;
  position: relative;
}
.swipe-wrap {
  position: relative;
  height: calc(100vh - (56px + 24px + 48px));
}
.content {
  float: left;
  width: 100%;
  position: relative;
  min-height: 416px;
  display: flex;
  flex-direction: column;
  z-index: -10;
}
